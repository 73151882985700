import React, { Component } from 'react';
import { Link } from 'gatsby';
import * as ROUTES from '../../constants/routes';

import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../../components/Session';


const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    const emailComponent = (
      <main className="pa4 black-80">
        <fieldset className="ba b--transparent ph0 mh0">
          <form className="measure center" onSubmit={this.onSubmit}>
            <AuthUserContext.Consumer>
              {authUser => (
                <h3>Account: {authUser.email}</h3>
              )}
            </AuthUserContext.Consumer>
            <input
              className="mt3 pa2 input-reset ba bg-transparent w-100"
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="New Password" />
            <input
              className="mt3 pa2 input-reset ba bg-transparent w-100"
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm New Password" />
            <button
              className="b ph3 mt3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib"
              disabled={isInvalid}
              type="submit">
              Reset My Password
            </button>
            {error && <p>{error.message}</p>}
          </form>
        </fieldset>
      </main>
    );

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !authUser.email ?
            <main className="pa4 black-80">
              <Link to={ROUTES.HOME}>Go back to application</Link>
            </main>
            :
            emailComponent
        )}
      </AuthUserContext.Consumer>

    );
  }
}

export default withFirebase(PasswordChangeForm);
